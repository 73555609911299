<template>
  <b-card class="login-body mb-5">

    <ErrorPopup :error="error_popup_message" />

    <div v-if="error_invalid_link">
      <div class="warning-message">
        <memlist-inline-alert>
          {{ $t('PUBLIC.LOGIN.INVALID_LOGIN_LINK') }}
        </memlist-inline-alert>
      </div>
    </div>


    <div v-if="loading" class="d-flex justify-content-center mb-8 mt-8">
      
      <memlist-spinner />
    
    </div>


    
    <div v-else-if="error_invalid_link" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
      <div class="alert-icon"><i class="flaticon-warning"></i></div>
      <div class="alert-text"><p>{{$t('ACCOUNT.INVALID_LINK')}}</p></div>
    </div>

    <div v-else>

      <div>
        <h4>{{ $t('PUBLIC.LOGIN.COMPANY_LOGIN_INFO', { company_name: company_name }) }}</h4>
      </div>

      <!-- if settings has both email and bankid -->
      <v-tabs v-if="enable_member_login_bankid && enable_member_login_email" background-color="white" color="accent-4" left>
        <v-tab>{{$t('ACCOUNT.TAB_BANKID')}}</v-tab>
        <v-tab>{{$t('ACCOUNT.TAB_EMAIL')}}</v-tab>
        
        <v-tab-item>
          <NativeBankIDComponent 
            class="mt-6"
            :company_id="form.company_id"
            intent="member"
            type="auth"
            @authenticated="member_authenticated"
          />
        </v-tab-item>
        
        <v-tab-item>
          <MemberEmailPasswordLoginComponent 
            class="mt-6"
            :company_id="form.company_id"
            @authenticated="member_authenticated"
          />
        </v-tab-item>
      </v-tabs>

      <!-- if settings has only email -->
      <div v-else-if="enable_member_login_email">
        <MemberEmailPasswordLoginComponent 
          :company_id="form.company_id"
          @authenticated="member_authenticated"
        />
      </div>

      <!-- if settings has only bankid -->
      <div v-else-if="enable_member_login_bankid">
        <NativeBankIDComponent 
          :company_id="form.company_id"
          intent="member"
          type="auth"
          @authenticated="member_authenticated"
        />
      </div>

      <div v-else>
        {{ $t('PUBLIC.LOGIN.DISABLED_LOGIN') }}
      </div>

    </div>

  </b-card>
</template>

<style lang="scss" scoped>

.heading-container {
  border-radius: 0.42rem;
  
  border: 1px solid #d7ddff;
  margin-top: 2px;
  padding: 0.5rem 1rem;
  padding-top: 12px;
  margin-bottom: 1rem;
  
}

.login-body {
  
  border: 1px solid #d7ddff;
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>

<script>

import axios from 'axios';

import store from '@/core/services/store';

import NativeBankIDComponent from '@/view/components/bankid/NativeBankIDComponent.vue';
import MemberEmailPasswordLoginComponent from '@/view/pages/members/auth/login/MemberEmailPasswordLoginComponent.vue';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import ErrorPopup from '@/view/components/ErrorPopup.vue';

import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';


import messageParent from '@/core/services/messageParent';

import { mapState } from 'vuex';
import { MEMBER_LOGIN, MEMBER_LOGOUT, MEMBER_SET_COMPANY, MEMBER_SET_LOGINLINK } from '@/core/services/store/member.auth.module';

import { validationMixin } from 'vuelidate';
import { email, minLength, required } from 'vuelidate/lib/validators';
import { set_session_customer_id } from '@/core/services/member.service';


export default {
  mixins: [ validationMixin, toasts ],
  name: 'MemberTabbedLogin',
  components: {
    NativeBankIDComponent,
    MemberEmailPasswordLoginComponent,
    ErrorPopup
  },

  props: ['redirect','is_modal'],

  data() {
    return {

      error_popup_message: null,

      loading: true,

      enable_member_login_bankid: true,
      enable_member_login_email: true,

      show_initializing: true,

      error_invalid_link: false,


      form: {
        email: null,
        password: null,
        company_id: null
      },

      nonce: null
    };
  },
 
  async mounted() {

    this.nonce = this.$route.query.nonce;

    if (this.nonce) {
      this.fetch_returned_auth();
    }

    this.form.email = getKeyValue('member_login_email');

    if (this.$route.params.company_id) {
      this.form.company_id = this.$route.params.company_id;
      this.$store.dispatch(MEMBER_SET_COMPANY, { company_id: this.form.company_id });
      
      const login_link = `/v2/login/${this.form.company_id}`;

      this.$store.dispatch(MEMBER_SET_LOGINLINK, login_link);

    }
    else {

      // get the system public data
      const res = await axios.get('/system/public');

      this.form.company_id = res.data.company_id;
      this.$store.dispatch(MEMBER_SET_COMPANY, { company_id: this.form.company_id });

      this.loading = false;
      return;
    }

    const settings = await this.get_public_data(this.form.company_id);

    if (settings) {
      this.enable_member_login_bankid = settings.enable_member_login_bankid;
      this.enable_member_login_email = settings.enable_member_login_email;
    }
    else {
      this.error_invalid_link = true;
    }

    set_session_customer_id(settings.customer_id);

    this.loading = false;
  },
  methods: {

    member_authenticated(data) {
      this.$store.dispatch(MEMBER_LOGIN, data);

      if (this.is_modal) {
        this.$emit('authenticated', data);
        return;
      }

      if (this.redirect) {
        this.$router.push({ name: this.redirect });
      }
      else {
        this.$router.push({ name: 'profile' });
      }
    },

    async fetch_returned_auth() {
      const res = await axios.post(`/native/bankid/returned`, {
        nonce: this.nonce
      });

      if (res.status === 200) {
        this.member_authenticated(res.data);
      }
    },

    async get_public_data(company_id) {
      try {
        const res = await axios.get(`/company/public-data/${company_id}`);

        if (res.status === 200) {
          return res.data;
        }

      }
      catch (err) {
        console.error('get_public_data', err);
      }

      return null;
    },

    async get_settings(company_id) {
      try {
        console.log('getting company ' + company_id);
        const res = await axios.get(`/company/public/${company_id}`);

        if (res.status === 200) {
          this.show_initializing = false;

          return res.data.company_settings;
        }

      }
      catch (err) {
        console.error('get_settings', err);
      }

      return null;
    },

  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    })
  }
};
</script>
